.avo-phone-input-label {
    @apply block text-sm font-medium text-gray-700;
}

.avo-phone-input-container {
    @apply mt-1;

    .avo-phone-input {
        @apply h-auto appearance-none block mt-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:text-sm;

        &.small {
            @apply py-1 pr-1 text-sm sm:text-xs;
        }

        &.medium {
            @apply py-2 pr-3 text-base sm:text-sm;
        }

        &.large {
            @apply py-3 pr-5 text-lg sm:text-base;
        }

        &.full-width {
            @apply w-full;
        }
    }
}
