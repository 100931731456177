.ticked-created-modal-container {
    @apply p-8;

    .ticket-created-modal-header {
        .ticket-created-icon {
            @apply h-36 text-avo-green;
        }

        .ticket-created-hodal-title {
            @apply text-5xl font-bold text-primary;

            span {
                @apply text-avo-green;
            }
        }
    }

    .ticket-created-modal-user-info-container {
        @apply py-6;

        > p {
            @apply text-gray-500;
        }

        .user-info-container {
            @apply pt-2;

            .user-info {
                @apply text-gray-700 font-bold py-2 px-4;

                &:nth-child(odd) {
                    @apply bg-gray-100;
                }
            }
        }
    }
}
