.avo-checkbox-container {
    .avo-checkbox-center {
        @apply flex items-center;

        .checkbox {
            @apply text-primary focus:ring-primary border-gray-300 rounded;
        }

        .avo-checkbox-label {
            @apply ml-2 block text-gray-900 select-none;
        }
    }

    &.label-left {
        .avo-checkbox-center {
            .avo-checkbox-label {
                @apply ml-0 mr-2;
            }
        }
    }

    &.small {
        .checkbox {
            @apply h-3 w-3;
        }

        .avo-checkbox-label {
            @apply text-xs;
        }
    }

    &.medium {
        .checkbox {
            @apply h-4 w-4;
        }

        .avo-checkbox-label {
            @apply text-sm;
        }
    }

    &.large {
        .checkbox {
            @apply h-6 w-6;
        }

        .avo-checkbox-label {
            @apply text-base;
        }
    }

    &.full-width {
        @apply w-full;
    }
}
