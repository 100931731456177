.select-entity-page-container {
    @apply h-screen flex flex-col items-center py-16;

    .select-entity-avoapp-logo-container {
        @apply flex-shrink-0 flex items-center justify-center pb-8;
    }

    .select-entity-page-title {
        @apply text-xl font-bold text-gray-900 mb-8;
    }

    .entities-list {
        @apply divide-y divide-gray-100 overflow-hidden bg-white shadow-sm  sm:rounded-xl;
        min-width: 30rem;

        .entity-list-item {
            @apply relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 hover:cursor-pointer sm:px-6;

            .entity-list-item-left-container {
                @apply flex min-w-0 gap-x-4;

                .entity-name {
                    @apply text-sm font-semibold leading-6 text-gray-900;
                }

                .entity-profile-status-container {
                    @apply mt-1 flex text-xs leading-5 text-gray-500;

                    .entity-profile-status {
                        @apply relative truncate;
                    }
                }
            }

            .entity-list-item-right-container {
                @apply flex flex-shrink-0 items-center gap-x-4;
            }

            &.disabled {
                @apply bg-gray-100 hover:cursor-default;

                .entity-list-item-left-container {
                    .entity-name {
                        @apply text-gray-500;
                    }
                }
            }
        }
    }
}
