#login-container {
    @apply min-h-screen flex;

    .login-left {
        @apply flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24;

        .title {
            @apply mt-6 text-2xl font-extrabold text-gray-900;
        }

        .logo {
            @apply h-auto w-auto;
        }

        .forgot-password-component-container {
            @apply flex items-center justify-end;

            .forgot-password-link {
                @apply font-medium text-primary hover:text-primary-dark;
            }
        }
    }

    .login-right {
        @apply hidden lg:block relative w-0 flex-1;

        img {
            @apply absolute inset-0 h-full w-full object-cover;
        }
    }
}
