.support-page-container {
    @apply w-full h-content flex justify-center items-center;

    .support-left,
    .support-right {
        @apply w-1/2;
    }

    .support-left {
        .support-title {
            @apply text-7xl font-bold text-primary;

            span {
                @apply text-avo-green;
            }
        }

        .support-text {
            @apply text-lg text-gray-500 mt-2;
        }

        .support-icon {
            @apply h-72 text-primary;
        }
    }

    .support-right {
        @apply space-y-6;

        .call-me-card,
        .create-ticket-card {
            @apply bg-white p-4 rounded space-y-6;

            .card-title {
                @apply text-gray-500 text-lg;
            }
        }

        .create-ticket-card {
            .create-ticket-form {
                @apply space-y-4;
            }
        }
    }
}
