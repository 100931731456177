.avo-loader {
    @apply text-white animate-spin;

    &.small {
        @apply w-4 h-4;
    }

    &.medium {
        @apply w-8 h-8;
    }

    &.large {
        @apply w-12 h-12;
    }
}
