.avo-input-label-container {
    label.avo-label {
        @apply block text-sm font-medium text-gray-700 mb-1;
    }

    .avo-input-wrapper {
        @apply relative text-gray-600 focus-within:text-gray-900;

        .input-icon-wrapper {
            @apply absolute w-5 inset-y-0 left-1 flex items-center pointer-events-none;

            & > * {
                @apply h-full;
            }
        }

        input.avo-input {
            @apply appearance-none block border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;

            &:disabled {
                @apply bg-gray-100 text-gray-400;
            }

            &[type="time"]::-webkit-datetime-edit-ampm-field {
                display: none;
            }
        }

        &.small {
            input.avo-input {
                @apply py-1 px-1 text-sm sm:text-xs;

                &.has-icon {
                    @apply px-0 pl-6 pr-1;
                }
            }
        }

        &.medium {
            input.avo-input {
                @apply py-2 px-3 text-base sm:text-sm;

                &.has-icon {
                    @apply pl-8 pr-3;
                }
            }
        }

        &.large {
            input.avo-input {
                @apply py-3 px-5 text-lg sm:text-base;

                &.has-icon {
                    @apply pl-10 pr-5;
                }
            }
        }
    }

    &.full-width {
        @apply w-full;

        input.avo-input {
            @apply w-full;
        }
    }
}
