.navbar-container {
    @apply relative z-10 flex-shrink-0 flex h-16 bg-white shadow;

    .navbar-icon {
        @apply h-6 w-6;
    }

    .hamburger-menu-button {
        @apply px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden;
    }

    .navbar-content-container {
        @apply flex-1 px-4 flex justify-between;

        .left-placeholder {
            @apply md:w-6;
        }

        .right-navbar-container {
            @apply ml-4 flex items-center md:ml-6;

            .notifications-link {
                @apply bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
            }
        }
    }
}
