.not-found-container {
    @apply bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8;

    .not-found-code-text {
        @apply text-4xl font-extrabold text-primary sm:text-5xl;
    }

    .text-container {
        @apply sm:border-l sm:border-gray-200 sm:pl-6;

        .page-not-found-text {
            @apply text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl;
        }

        .check-url-text {
            @apply mt-1 text-base text-gray-500;
        }
    }

    .link-container {
        @apply mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6;

        .go-home-link {
            @apply items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light;
        }
    }
}
