.register-container {
    @apply min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8;

    .header-container {
        @apply sm:mx-auto sm:w-full sm:max-w-md;

        .title {
            @apply mt-6 text-center text-3xl font-extrabold text-gray-900;
        }

        .logo {
            @apply h-20 w-auto m-auto;
        }
    }

    .terms-link {
        @apply underline text-primary;
    }

    .error-section {
        @apply mb-5;
    }

    .back-to-login-container {
        @apply mt-1;

        .back-to-login-link {
            @apply text-xs font-medium text-gray-500 hover:text-black;
        }
    }
}
