.entity-profile-details-card {
    @apply divide-y divide-gray-200 overflow-hidden bg-white rounded-lg border border-gray-200   shadow;

    .entity-profile-name-container {
        @apply px-2 py-3 sm:px-3;

        .entity-profile-name {
            @apply text-base font-semibold leading-6 text-gray-900;
        }
    }

    .entity-profile-details-card-content-container {
        @apply px-2 py-3 sm:p-3;

        .entity-profile-property {
            @apply text-sm leading-6 text-gray-900;

            .entity-profile-property-key {
                @apply font-semibold;
            }
        }
    }
}
