.no-account-container {
    @apply mt-10;

    .separator-container {
        @apply relative;

        .line-container {
            @apply absolute inset-0 flex items-center;

            .line {
                @apply w-full border-t border-gray-300;
            }
        }

        .text-container {
            @apply relative flex justify-center text-sm;

            .text {
                @apply px-2 bg-white text-gray-500;
            }
        }
    }

    .create-account-link-container {
        @apply mt-6 grid place-items-center;

        .create-account-link {
            @apply w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50;
        }
    }
}
