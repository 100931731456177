.avo-modal-content-container {
    @apply relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6;

    &.header-hidden {
        @apply pt-0;
    }

    .avo-modal-title {
        @apply text-lg leading-6 font-medium text-center text-gray-900 mb-5;
    }
}
