.adv-card-container {
    @apply divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow w-full;

    .adv-card-title-container {
        @apply px-4 py-5 sm:px-6;

        .adv-card-title {
            @apply text-base font-semibold leading-6 text-gray-900;
        }
    }

    .adv-card-content-container {
        @apply px-4 py-5 sm:p-6;
    }
}
