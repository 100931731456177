.wrapper {
  @apply w-full h-screen flex flex-col gap-5 items-center bg-gray-100;

  img {
    @apply max-w-96;
  }

  .info-box {
    @apply rounded-lg bg-white shadow px-20 py-5;
  }
}
