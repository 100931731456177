.avo-errors-modal-content-container {
    .avo-errors-modal-message-container {
        .avo-errors-modal-message {
            @apply text-xl font-semibold text-center;
        }
    }

    .avo-errors-modal-buttons-container {
        @apply flex gap-2 justify-between  mt-4;
    }
}
